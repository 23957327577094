import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { userActions } from "../store/actions/user.action";

const headers = [
    {
        key: "time",
        title: "Time"
    },
    {
        key: "transfername",
        title: "Transfer User"
    },
    {
        key: "receivename",
        title: "Receive User"
    },
    {
        key: "amount",
        title: "Amount"
    },
]

const perPage = 10;

export default function History() {

    const { logs, logCount } = useSelector(state => state.users);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getLoyaltyLogs({page: page+1}));
    }, [page, dispatch]);

    const handlePageChange = (e, value) => {
        setPage(value);
    }

    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="lg" sx={{ mt: 3 }}>
                <TableContainer
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headers.map(({key, title}) => (
                                    <TableCell key={key} 
                                    sx={{
                                        fontWeight: "500",
                                        textAlign: (key==="amount")? "right": "left",
                                        paddingRight: (key==="amount")? "5rem": 0,
                                    }}>
                                        {title}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(logs && logs.length > 0) ? logs.map((item, index) => (
                                <TableRow key={index}>
                                    {headers.map(({key, title}) => (
                                        <TableCell sx={{
                                            padding: "15px",
                                            textAlign: (key==="amount")? "right": "left",
                                            paddingRight: (key==="amount")? "5rem": 0,
                                        }} key={key}>
                                            {item[key]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )):
                            <TableRow>
                                <TableCell colSpan={headers.length + 1} sx={{ textAlign: 'center' }}>No data found</TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination 
                    rowsPerPageOptions={[]}
                    component="div"
                    count={logCount}
                    rowsPerPage={perPage}
                    page={page}
                    onPageChange={handlePageChange}
                />
            </Container>
        </>
    );
}