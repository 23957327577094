import { getAuthInfo } from "../../utils/auth";
import { authConst } from "../constants/auth.constant";

const authInfo = JSON.parse(getAuthInfo());
const initialState = {
    isAuth: authInfo? true :false,
    authInfo: authInfo? authInfo: {}
};

export function authenticate(state=initialState, action) {
    switch(action.type) {
        case authConst.SET_AUTH_USER:
            return {
                ...state,
                isAuth: action.isAuth,
                authInfo: action.authInfo
            };
        default:
            return state;
    }
}