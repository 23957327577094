import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import logo from "../assets/menus_logo.jpg";
import menuslogo from "../assets/menus_logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/actions/auth.action';
import routes from '../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authInfo = useSelector(state => state.auth.authInfo);
  const { name, phoneno } = authInfo;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleCloseNavMenu();
  }

  const handleLogout = () => {
    dispatch(authActions.logout());
  }

  const { pathname } = useLocation();

  return (
    <AppBar position="static" color="white">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box 
            sx={{
              display: { xs: 'none', md: 'flex' },
              mr: 1,
              height: "64px"
            }}
          >
          <img alt="logo" style={{height: '64px'}} src={logo}/>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {routes.map((page) => (
                <MenuItem key={page.key} onClick={() => handleMenuClick(page.path)}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Box 
            sx={{
              display: { xs: 'flex', md: 'none' },
              mr: 1,
              height: "64px"
            }}
          >
            <img alt="logo" src={logo}/>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {routes.map((page) => (
              <Button
                color="neutral"
                key={page.key}
                onClick={() => handleMenuClick(page.path)}
                sx={{ 
                  my: 2, 
                  color: (page.path===pathname)? "#f05a28": "white",
                  display: 'block'
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Box onClick={handleOpenUserMenu} sx={{cursor: "pointer"}}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography textAlign="right">{name??"Loading"}</Typography>
                    <Typography textAlign="right" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{phoneno??"Loading"}</Typography>
                  </Grid>
                  <Grid item>
                    <Avatar src={menuslogo} />
                  </Grid>
                </Grid>
              </Box>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem key="logout">
                  <Button onClick={handleLogout}>
                    Logout
                  </Button>
                  {/* <Typography textAlign="center">Logout</Typography> */}
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;