import { getToken, setToken } from "../../utils/token";
import { useEffect, useRef, useState } from "react";
import loginIllustration from "../../assets/login@3x.png";
import logo from "../../assets/menus_logo.jpg";
import "./Login.css";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/actions/auth.action";
import { apiService } from "../../api/api.service";

export default function Login() {
    const usernameRef = useRef();
    const passwordRef = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        let username = usernameRef.current.value;
        let password = passwordRef.current.value;
        dispatch(authActions.login({username, password}));
        return true;
    }

    // const authenticate = async() => {
    //     if (loading) return;
    //     setLoading(true);
        // let username = usernameRef.current.value;
        // let password = passwordRef.current.value;
    //     apiService.call("put", "sale-login?username="+username+"&password="+password)
    //         .then((res) => {
    //             console.log(res.status);
    //             if (res.status >= 500 || res.message) {
    //                 alert(res.data?.message);
    //             } else {
    //                 if (window !== undefined) {
    //                     setAuthInfo(JSON.stringify(res.data));
    //                     // router.push("/app/dashboard")
    //                 }
    //             }
    //             setLoading(false);
    //         });
    // }

    useEffect(() => {
        let token = getToken();
        console.log(token);
        if (token!==null) {
            apiService.setAuthHeader(token);
        } else {
            apiService.call("post", "getToken").then((res) => {
                if (res.token) {
                    apiService.setAuthHeader(res.token);
                    setToken(res.token);
                }
            });
        }
    }, []);

    return (
        <>
            <main style={{height: "100%", marginTop: "160px", marginLeft: "140px"}}>
                <div className="login_img">
                    <img width="30%" src={loginIllustration} />
                </div>
                <div className="login">
                    <img src={logo} className="logo_img" />
                    <div className="login_header">
                        <h5 className="color_orange remove_margin" style={{fontSize: "20px"}}>Login</h5><br/><br/>
                        <span style={{fontSize: "12px", color: "gray"}}>Please login with your username and password to continue.</span>
                    </div>
                    <form role="form" onSubmit={handleSubmit}>              
                        <div className="login_form">
                            <h3 className="color_orange remove_margin" style={{fontSize: "14px"}}>Username:</h3>
                            <input ref={usernameRef} type="text" placeholder="Username" name="username" required/><br/>
                            <h3 className="color_orange remove_margin" style={{fontSize: "14px"}}>Password:</h3>
                            <input ref={passwordRef} type="password" placeholder="Password" name="password" required/>
                            <br/>
                            <button type="submit" className="login_button login_btn">Login</button>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
}