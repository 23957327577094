const auth = "authInfo";

export const getAuthInfo = () => (
    localStorage.getItem(auth)
);

export const setAuthInfo = (value) => (
    localStorage.setItem(auth, value)
)

export const deleteAuthInfo = () => {
    localStorage.removeItem(auth);
}