import { apiService } from '../../api/api.service';
import { deleteAuthInfo, setAuthInfo } from '../../utils/auth';
import { authConst } from '../constants/auth.constant';
import { statusActions } from './status.action';

const setAuthUser = (authInfo, isAuth) => ({
    type: authConst.SET_AUTH_USER,
    authInfo,
    isAuth 
});

const login = ({username, password}) => {
    return async(dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const response = await apiService.call("put", "sale-login?username="+username+"&password="+password);
            const authInfo = response;
            setAuthInfo(JSON.stringify(authInfo));
            dispatch(setAuthUser(authInfo, true));
            // dispatch(statusActions.setSuccess(true));
            // dispatch(statusActions.setMessage("Logged in successfully!"));
            
        } catch(err) {
            deleteAuthInfo();
            dispatch(setAuthUser({}, false));
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(err?.response?.data?.message));
        }
        dispatch(statusActions.setLoading());
    }
}

const logout = () => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        deleteAuthInfo();
        dispatch(setAuthUser({}));
        // dispatch(statusActions.setSuccess(true));
        // dispatch(statusActions.setMessage("Logged out successfully"));
        dispatch(statusActions.setLoading());
    }
}

export const authActions = {
    login,
    logout
}