const TOKEN = "saleModuleToken";

export const getToken = () => {
    return localStorage.getItem(TOKEN)
};

export const setToken = (token) => (
    localStorage.setItem(TOKEN, token)
)

export const deleteToken = () => {
    localStorage.removeItem(TOKEN);
}