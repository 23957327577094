import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import routes from "./routes";
import PrivateRoutes from "./routers/PrivateRoutes";
import PublicRoutes from "./routers/PublicRoutes";
import { Alert, CircularProgress, createTheme, Dialog, Snackbar, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { statusActions } from "./store/actions/status.action";


function App() {

  const isAuth = useSelector((state) => state.auth.isAuth);
  const { loading, success, message } = useSelector(state => state.status);

  const dispatch = useDispatch();

  const handleSnackBarClose = () => {
    dispatch(statusActions.setMessage(null));
    dispatch(statusActions.setSuccess(false));
  }

  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#f05a28',
        darker: '#823116',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
      white: {
        main: '#fff',
        contrastText: '##4c4c4c',
      },
    },
    typography: {
      fontFamily: 'NotoSans, sans-serif',
      fontSize: 12,
      fontWeight: "400",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Suspense>
        <Routes>
          <Route element={PrivateRoutes(isAuth)} >
            {routes.map((route) => (
              <Route exact path={route.path} element={route.component} key={route.key} />
            ))}
          </Route>
          <Route element={PublicRoutes(isAuth)} >
            <Route key="login" exact path="/auth/login" element={<Login />} />
          </Route>
          <Route exact path="/" element={<Navigate to="/app/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/app/dashboard" replace />} />
        </Routes>
      </Suspense>
      <LoadingDialog open={loading}/>
      <Snackbar onClose={handleSnackBarClose} anchorOrigin={{vertical: "top", horizontal: "right"}} open={message!==null} autoHideDuration={5000}>
        <Alert severity={success?"success": "error"}>{message}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;

function LoadingDialog({open}) {
  return (
      <Dialog
          open={open}
          PaperProps={{
              style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              overflow: 'hidden'
              },
          }}
          // onClose={handleCloseLoading}
          >
              <CircularProgress/>
          </Dialog>
  );
}