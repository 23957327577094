import { userConstants } from "../constants/user.constant";

const initialState = {
    userList: [],
    userCount: 0,
    user: null,
    logs: [],
    logCount: 0,
}

export default function users(state=initialState, action) {
    switch (action.type) {
        case userConstants.SET_USERS:
            return {
                ...state,
                userList: action.users,
                userCount: action.count
            };
        case userConstants.UPDATE_USERS:
            return {
                ...state,
                userList: state.userList.map(user => user.userid === action.user.userid ? action.user: user),
            };
        case userConstants.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case userConstants.SET_LOGS:
            return {
                ...state,
                logs: action.logs,
                logCount: action.count,
            }
        default:
            return state;
    }
}