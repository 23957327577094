import { applyMiddleware, combineReducers, compose, legacy_createStore } from "redux";
import thunk from "redux-thunk";
import { authenticate } from "./reducers/auth.reducer";
import { status } from "./reducers/status.reducer";
import users from "./reducers/user.reducer";

const DEFAULT_STATE = {};

const store = legacy_createStore(combineReducers({
    auth: authenticate,
    status,
    users
}), DEFAULT_STATE, compose(applyMiddleware(thunk)));

export default store;