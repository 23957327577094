import { Dashboard as DashboardIcon, History as HistoryIcon } from "@mui/icons-material";
import Dashboard from "./pages/Dashboard";
import History from "./pages/History";

const routes = [
    {
        title: "Dashboard",
        key: "dashboard",
        icon: DashboardIcon,
        path: "/app/dashboard",
        component: <Dashboard />
    },
    {
        title: "History",
        key: "history",
        icon: HistoryIcon,
        path: "/app/history",
        component: <History />
    },
]

export default routes;