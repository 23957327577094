import { apiService } from "../../api/api.service";
import { userConstants } from "../constants/user.constant";
import { statusActions } from "./status.action";

const setUsers = (users, count) => ({
    type: userConstants.SET_USERS,
    users,
    count,
});

const updateUsers = (user) => ({
    type: userConstants.UPDATE_USERS,
    user
});

const setUser = (user) => ({
    type: userConstants.SET_USER,
    user
});

const setLogs = (logs, count) => ({
    type: userConstants.SET_LOGS,
    logs,
    count
});

const getUsers = ({page, phone=""}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const {data, count} = await apiService.call("get", "get-users-by-phone?phone="+phone.replace("+","%2B")+"&page="+page);
            
            dispatch(setUsers(data, count));
            // dispatch(statusActions.setSuccess(true));
            // dispatch(statusActions.setMessage("Successfully fetch users"));
        } catch (err) {
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(err?.response?.data?.message));
        }
        dispatch(statusActions.setLoading());
    }
}

const getUser = ({phone}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const users = await apiService.call("get", "get-user-by-phone?phone="+phone);
            
            if (users.length > 0) {
                dispatch(setUser(users[0]));
                // dispatch(statusActions.setSuccess(true));
                // dispatch(statusActions.setMessage("Successfully fetch user"));
            } else {
                dispatch(setUser(null));
                dispatch(statusActions.setSuccess(false));
                dispatch(statusActions.setMessage("User not found"));
            }
        } catch (err) {
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(err?.response?.data?.message));
        }
        dispatch(statusActions.setLoading());
    }
}

const updatePoints = ({userid, points, authuserid}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const user = await apiService.call("put", "update-loyalty-points?userid="+userid+"&loyaltypointbalance="+points+"&authuserid="+authuserid);
            dispatch(updateUsers(user));
            // dispatch(statusActions.setSuccess(true));
            // dispatch(statusActions.setMessage("Successfully updated points"));
        } catch (err) {
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(err?.response?.data?.message));
        }
        dispatch(statusActions.setLoading());
    }
}

const getLoyaltyLogs = ({page}) => {
    return async (dispatch) => {
        dispatch(statusActions.setLoading());
        try {
            const { count, data } = await apiService.call("get", "get-loyalty-logs?page="+page);
            dispatch(setLogs(data.map((log) => ({
                ...log,
                time: new Date(log.createdtime).toLocaleString("en-US", {timeZone: "Asia/Singapore"}),
            })), count));
            // dispatch(statusActions.setSuccess(true));
            // dispatch(statusActions.setMessage("Successfully fetch data"));
        } catch (err) {
            dispatch(statusActions.setSuccess(false));
            dispatch(statusActions.setMessage(err?.response?.data?.message));
        }
        dispatch(statusActions.setLoading());
    }
}

export const userActions = {
    setUser,
    getUsers,
    getUser,
    updatePoints,
    getLoyaltyLogs,
}