import { Add, Clear } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { userActions } from "../store/actions/user.action";

const headers = [
    {
        key: "name",
        title: "Name"
    },
    {
        key: "phoneno",
        title: "Phone Number"
    },
    {
        key: "loyaltypointbalance",
        title: "Amount"
    }
]

const perPage = 10;

export default function Dashboard() {
    // const phoneRef = useRef();
    // const pointRef = useRef();
    // const dispatch = useDispatch();

    const { user } = useSelector(state => state.users);
    // const { userid } = useSelector(state => state.auth.authInfo);

    // const handleSearch = () => {
    //     let phone = phoneRef.current.value;
    //     dispatch(userActions.getUser({phone}));
    // }

    // const handleClear = () => {
    //     phoneRef.current.value = ""
    //     dispatch(userActions.setUser(null));
    // }

    // const handleUpdate = () => {
    //     let points = pointRef.current.value;
    //     dispatch(userActions.updatePoints({ userid: user.userid, points, authuserid: userid }));
    //     handleClear();
    // }

    return (
        <>
            <ResponsiveAppBar />
            <UserListTable />
            <Dialog open={user!==null}>
                <UpdatePointCardForm 
                    // user={user}
                    // phoneRef={phoneRef}
                    // pointRef={pointRef}
                    // handleClear={handleClear}
                    // handleUpdate={handleUpdate}
                    // handleSearch={handleSearch}
                />
            </Dialog>
        </>
    );
}

function UpdatePointCardForm(
    // {user, phoneRef, pointRef, handleClear, handleUpdate, handleSearch}
    ) {

    const phoneRef = useRef();
    const pointRef = useRef();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.users);
    const { userid } = useSelector(state => state.auth.authInfo);

    const handleSearch = () => {
        let phone = phoneRef.current.value;
        dispatch(userActions.getUser({phone}));
    }

    const handleCancel = () => {
        dispatch(userActions.setUser(null));
    }

    const handleUpdate = () => {
        let points = pointRef.current.value;
        dispatch(userActions.updatePoints({ userid: user.userid, points, authuserid: userid }));
        handleCancel();
    }

    return (
        <Dialog open={user!==null} onClose={handleCancel}>
            <DialogContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Update loyalty point balance for the user
                </Typography>
                <Box sx={{ mt: 2 }} />
                <TextField disabled defaultValue={user?.phoneno} size="small" fullWidth label="Phone Number" />
                {user && 
                (<>
                    <Box sx={{ mt: 2 }} />
                    <TextField disabled defaultValue={user?.name} size="small" fullWidth label="Name" />
                    <Box sx={{ mt: 2 }} />
                    <TextField inputRef={pointRef} size="small" fullWidth label="Points" />
                </>)
                }
            </DialogContent>
            <DialogActions>
                {user ? 
                (<>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleUpdate}>Update</Button>
                </>) :
                (<Button onClick={handleSearch}>Search</Button>)
                }
            </DialogActions>
        </Dialog>
    );
    // return (
    //     <Container maxWidth="sm" sx={{ mt: 3 }}>
    //         <Card>
                // <CardContent>
                //     <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                //         Search user and update loyalty point balance
                //     </Typography>
                //     <Box sx={{ mt: 2 }} />
                //     <TextField disabled={user?true:false} inputRef={phoneRef} size="small" fullWidth label="Phone Number" />
                //     {user && 
                //     (<>
                //         <Box sx={{ mt: 2 }} />
                //         <TextField disabled value={user?.name} size="small" fullWidth label="Name" />
                //         <Box sx={{ mt: 2 }} />
                //         <TextField inputRef={pointRef} size="small" fullWidth label="Points" />
                //     </>)
                //     }
                // </CardContent>
                // <CardActions>
                //     {user ? 
                //     (<>
                //         <Button onClick={handleClear}>Clear</Button>
                //         <Button onClick={handleUpdate}>Update</Button>
                //     </>) :
                //     (<Button onClick={handleSearch}>Search</Button>)
                //     }
                // </CardActions>
    //         </Card>
    //     </Container>
    // );
}

function UserListTable() {
    const dispatch = useDispatch();

    const { userList, userCount } = useSelector(state => state.users);
    const [page, setPage] = useState(0);
    const [value, setValue] = useState("");
    const [debounce, setDebounce] = useState(0);

    const handleClearValue = () => {
        setValue("");
        dispatch(userActions.getUsers({page: page+1}));
        setPage(0);
    }

    const handleOpenForm = (data) => {
        dispatch(userActions.setUser(data));
    }

    useEffect(() => {
        dispatch(userActions.getUsers({page: page+1}));
    }, [page, dispatch]);

    const handlePhoneChange = (e) => {
        if (debounce) clearTimeout(debounce);
        setDebounce(setTimeout(() => {
            dispatch(userActions.getUsers({page: 1, phone: e.target.value}));
            setPage(0);
        }, 500));
    }

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            dispatch(userActions.getUsers({page: 1, phone: e.target.value}));
            setPage(0);
        }
    }

    const handlePageChange = (e, value) => {
        setPage(value);
    }
    return (
        <Container maxWidth="lg" sx={{ mt: 3 }}>
            <Grid container>
                <Grid item sx={{flexGrow: 1}} >
                    <Typography variant="h5">User List</Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Search user and update loyalty point balance
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField 
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        value={value}
                        onKeyDown={handlePressEnter} 
                        size="small" 
                        label="Search by phone number" 
                        InputProps={{
                            endAdornment: (
                                <IconButton sx={{
                                        ml: 2,
                                        visibility: (value === "")? 'hidden': 'visible'
                                    }}
                                    onClick={handleClearValue}
                                >
                                    <Clear />
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer
                component={Paper}
                sx={{
                    mt: 2
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(({key, title}) => (
                                <TableCell key={key} 
                                sx={{
                                    fontWeight: "500",
                                    textAlign: (key==="loyaltypointbalance")? "right": "left",
                                    paddingRight: (key==="loyaltypointbalance")? "5rem": 0,
                                }}>
                                    {title}
                                </TableCell>
                            ))}
                            <TableCell key='action' 
                                sx={{
                                    fontWeight: "bold",
                                    padding: "15px 15px 0 15px"
                                }} 
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(userList && userList.length > 0) ? userList.map((item, index) => (
                            <TableRow key={index}>
                                {headers.map(({key, title}) => (
                                    <TableCell sx={{
                                        padding: "15px",
                                        textAlign: (key==="loyaltypointbalance")? "right": "left",
                                        paddingRight: (key==="loyaltypointbalance")? "5rem": 0,
                                    }} key={key}>
                                        {item[key]}
                                    </TableCell>
                                ))}
                                <TableCell
                                    align="center"
                                    sx={{
                                        padding: "13px"
                                    }}
                                    key='action'
                                >
                                    <Button
                                    sx={{
                                        padding: "3px",
                                        color: "rgba(102,102,102)",
                                        minWidth: "auto",
                                        backgroundColor: "transparent",
                                        border: "0.8px solid rgba(0,0,0,0.2)",
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            border: "0.8px solid rgba(0,0,0,0.2)",
                                        }
                                    }}
                                    // data={JSON.stringify(item)}
                                    onClick={() => handleOpenForm(item)}
                                    variant="outlined"
                                    size="small"
                                    >
                                        <Add fontSize="small"/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )):
                        <TableRow>
                            <TableCell colSpan={headers.length + 1} sx={{ textAlign: 'center' }}>No data found</TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination 
                rowsPerPageOptions={[]}
                component="div"
                count={userCount}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handlePageChange}
            />
        </Container>
    );
}